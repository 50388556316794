import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Input, AutoComplete, Spin, Button, Flex, Switch } from "antd";
import { LeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import debounce from "lodash/debounce";
import axios from "axios";
import CreateReport from "../CreateReport";
import API from "../../utils/api";

import { Space, Table, Tag } from "antd";
import Cookies from "universal-cookie";
import "../UserManagement/CMSUserList/UserList.css";

function formatDateToDMY(originalDate) {
  const parsedDate = new Date(originalDate);
  const day = parsedDate.getDate();
  const month = parsedDate.getMonth() + 1; // Months are zero-based
  const year = parsedDate.getFullYear();

  // Add leading zero if day or month is a single digit
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}-${formattedMonth}-${year}`;
}

const { Option } = AutoComplete;

const UpdateReport = () => {
  // const [data, setData] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPublished, setIsPublished] = useState(true);
  const [hide, setHide] = useState(
    localStorage.getItem("HIDE_UPDATE") === "true"
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "synapse_report_id",
      key: "synapse_report_id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "Year",
      dataIndex: "base_year",
      key: "base_year",
    },
    {
      title: "Parent Report",
      key: "parent_rd",
      dataIndex: "parent_rd",
      render: (_, record) => (
        <Space size="middle">{record?.parent_rd?.title}</Space>
      ),
    },
    {
      title: "Forecast Year",
      key: "forecast_year",
      dataIndex: "forecast_year",
    },
    {
      title: "Version",
      key: "version",
      dataIndex: "version",
    },
    {
      title: "Last Modified",
      key: "updatedAt",
      dataIndex: "updatedAt",
      render: (text) => <p>{formatDateToDMY(text)}</p>,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => deactiveReport(record.synapse_report_id)}>
            {record.active ? "Deactivate" : "Activate"}
          </Button>
          <Button onClick={() => onSelect(record._id)}>Update</Button>
        </Space>
      ),
    },
  ];

  const { Base_URL, searchReport } = API;
  const cookies = new Cookies();
  let token = cookies.get("TOKEN");

  const fetchReports = async () => {
    setLoading(true);
    try {
      // Perform API request to fetch options based on value
      const response = await axios.get(
        `${Base_URL}getSynapseReports?published=${isPublished}&search=${searchText}`
      );
      const data = response.data.data;
      setReportsData(data);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false); // Set loading to false when request completes
    }
  };
  const deactiveReport = async (id) => {
    try {
      const response = await axios({
        url: `${Base_URL}deleteSynapseReport/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      // const response = await axios.patch(
      //   `${Base_URL}deleteSynapseReport/${id}`
      // );
      const data = response.data.message;
      if (data) {
        toast.success(data, {
          position: "top-right",
          autoClose: 5000,
        });
        fetchReports();
      } else {
        toast.error(data, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const filteredData = reportsData?.filter((item) =>
    item.title.toLowerCase().includes(searchText.toLowerCase())
  );

  // const filteredData = reportsData?.filter((item) => {
  //   if (
  //     item.title.toLowerCase().includes(searchText.toLowerCase()) &&
  //     item.published === isPublished
  //   ) {
  //     return item;
  //   }
  // });

  useEffect(() => {
    fetchReports();
  }, [isPublished, searchText]);

  useEffect(() => {
    if (!hide) {
      fetchReports();
    }
  }, [hide]);

  const debouncedSearch = useCallback(
    debounce((nextValue) => setSearchText(nextValue), 100),
    [] // Dependency array
  );

  const onSelect = (value) => {
    setHide(true);
    const sessionData = reportsData.filter((item) => item._id === value);

    // Here we are storing the data whick we want to update in the CreateReport component
    localStorage.setItem("FOR_UPDATE", JSON.stringify(sessionData));
    localStorage.setItem("HIDE_UPDATE", true);
    // setOptions([]);
  };

  // if (loading) {
  //   return (
  //     <Flex align="center" gap="middle" justify="center">
  //       <Spin size="large" />
  //     </Flex>
  //   );
  // }

  return (
    <>
      {hide ? (
        <Box>
          <Box mx="20%" width={"100%"} my="2%">
            <Button
              icon={<LeftOutlined />}
              onClick={() => {
                localStorage.removeItem("HIDE_UPDATE");
                localStorage.removeItem("FOR_UPDATE");
                setHide(false);
                //Also set existing search text to search input
                setSearchText(searchText);
              }}
            >
              Go Back
            </Button>
          </Box>
          <CreateReport
            hide={hide}
            setHide={setHide}
            handleRefetch={() => {
              fetchReports();
            }}
          />
        </Box>
      ) : (
        <Box height={"100%"}>
          <Box
            my={2}
            display={"flex"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            gap={2}
          >
            <Input
              style={{
                width: "20%",
                //Highlight the search text
                border: "1px solid #67bfff",
              }}
              placeholder="Search Report"
              prefix={<SearchOutlined />}
              value={searchText}
              onChange={(e) => debouncedSearch(e.target.value)}
            />
            {/* <Input defaultValue="Combine input and button" /> */}
            <Link to="/home/content-management/create-report">
              <Button type="primary">Create New Report</Button>
            </Link>
          </Box>

          <Box
            sx={{
              marginBottom: "0.5rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography sx={{ marginRight: "1rem" }}>
              {reportsData.length}{" "}
              {reportsData.length > 1 ? "reports" : "report"} found
              {searchText && ` for "${searchText}"`}
            </Typography>
            <Switch
              checkedChildren="Published"
              unCheckedChildren="Unpublished"
              onChange={(checked) => setIsPublished(checked)}
              checked={isPublished}
            />
          </Box>

          <Table
            className="custom-table-style"
            style={{ width: "100%" }}
            columns={columns}
            dataSource={reportsData}
            loading={loading}
          />
        </Box>
      )}
    </>
  );
};

export default UpdateReport;
