import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  InputNumber,
  Select,
  Radio,
  Row,
  Col,
  Spin,
} from "antd";
import {
  useCreateNewUserMutation,
  useEditNewUserMutation,
  useGetRelationShipManagerEmailQuery,
} from "../../../redux/api/createUserApi";
import { toast } from "react-toastify";
import _ from "lodash";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useLazyGetHubListQuery } from "../../../redux/api/assignReportApi";

const NewCreateUser = ({ userData, setToSearchScreen }) => {
  const [form] = Form.useForm();
  const cookies = new Cookies();
  const role = cookies.get("ROLE");
  const isPartner = cookies.get("ISPARTNER");

  const customLabelStyle = {
    display: "flex",
    flexDirection: "column", // Ensures label is above the input
    marginBottom: "8px", // Adjust as needed for spacing
  };
  const generatePassword = () => {
    // const password = Math.random().toString(36).slice(-8);
    //Generate password with atleast one capital letter
    const password = Math.random().toString(36).slice(-8) + "A";
    form.setFieldsValue({ password });
  };

  const accountTypes = {
    Admin: "owner",
    View: "view only",
    "View and Download": "view & download",
  };

  const {
    data: relationshipManagerEmailData,
    isLoading: relationshipManagerEmailLoading,
    isError: relationshipManagerEmailError,
  } = useGetRelationShipManagerEmailQuery();

  const [getHubList, { data: hubListData }] = useLazyGetHubListQuery();

  useEffect(() => {
    if (role === "SALES_EXECUTIVE") {
      getHubList();
    }
  }, [role]);

  const onFinish = (values) => {
    const newValue = {
      library_credits: {
        total: values.creditsLibraryTotal,
        // remaining: values.creditsLibraryRemaining,
      },
      ondemand_credits: {
        total: values.onDemandTotal,
        // remaining: values.onDemandRemaining,
      },
      company_name: values.company,
      analyst_hours: values.analystHours ?? 0,
      name: values.name,
      email: values.email,
      password: values.password,
      relationship_manager_email: values.relationshipManagerEmail,
      relationship_manager_username: relationshipManagerEmailData?.data.find(
        (item) => item.email_id === values.relationshipManagerEmail
      ).name,
      account_type: accountTypes[values.accountType],
      platform: values.platform,
      custom_user: values.custom_user,
    };
    const updatedValue = {};
    if (userData) {
      updatedValue.user_id = userData._id;
      updatedValue.update = newValue;
      //Delete password from update object
      delete updatedValue.update.password;
      editNewUser(updatedValue);
      return;
    }
    if (role === "SALES_EXECUTIVE") {
      //Create selected hub list id with name
      const hubList = values.hubList.map((item) => {
        return {
          id: item,
          name: hubListData?.data.find((hub) => hub.id === item).name,
        };
      });
      newValue.hub_list = hubList;
    }
    createNewUser(newValue);
  };

  const [
    createNewUser,
    {
      data: createNewUserData,
      isLoading: createNewUserLoading,
      error: createNewUserError,
      isSuccess: createNewUserSuccess,
    },
  ] = useCreateNewUserMutation();

  const [
    editNewUser,
    {
      data: editNewUserData,
      isLoading: editNewUserLoading,
      error: editNewUserError,
      isSuccess: editNewUserSuccess,
    },
  ] = useEditNewUserMutation();

  useEffect(() => {
    if (createNewUserError) {
      toast.error(createNewUserError.data.message ?? "Failed to create user");
    }

    if (createNewUserSuccess) {
      toast.success("User created successfully");
      form.resetFields();
    }
  }, [createNewUserError, createNewUserSuccess]);

  useEffect(() => {
    if (editNewUserError) {
      toast.error(editNewUserError.data.message ?? "Failed to edit user");
    }

    if (editNewUserSuccess) {
      toast.success("User edited successfully");
      form.resetFields();
      setToSearchScreen(true);
    }
  }, [editNewUserError, editNewUserSuccess]);
  const [rules, setRules] = useState([
    {
      required: true,
      message: "Please input your password!",
    },
    {
      min: 8,
      message: "Password must be at least 8 characters long",
    },
    //Capital letter is mandatory in password
    {
      pattern: new RegExp("^(?=.*[A-Z])"),
      message: "Password must contain at least one capital letter",
    },
  ]);
  useEffect(() => {
    if (userData) {
      //Set Empty array of rules for password field
      setRules([]);

      form.setFieldsValue({
        name: userData?.name,
        email: userData?.email,
        password: userData?.password,
        company: userData?.company_name,
        platform: _.capitalize(userData?.platform),
        // accountType: userData.account_type,
        accountType: _.invert(accountTypes)[userData?.account_type],
        relationshipManagerEmail: userData?.relationship_manager?.[0]?.email,
        analystHours:
          userData?.remaining_analyst_hours ?? userData?.analyst_hours,
        creditsLibraryTotal: userData?.library_credits?.remaining,
        // creditsLibraryRemaining: userData.library_credits.remaining,
        onDemandTotal: userData?.ondemand_credits?.remaining,
        // onDemandRemaining: userData.ondemand_credits.remaining,
        custom_user: userData?.custom_user,
      });
    }
  }, [userData]);

  const { Option } = Select;

  if (relationshipManagerEmailError) {
    toast.error("Failed to fetch Relationship Manager Email");
  }
  return (
    <>
      <Spin spinning={createNewUserLoading || editNewUserLoading} fullscreen />
      {userData && (
        <Button
          icon={<LeftOutlined />}
          onClick={() => {
            // window.location.reload();
            setToSearchScreen(true);
          }}
        >
          Go To User List
        </Button>
      )}
      <Form
        style={{
          width: "50%",
          margin: "auto",
          marginTop: "1rem",
          position: "relative",
        }}
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 15 }}
      >
        <Form.Item
          label={<span style={{ fontSize: "0.875rem" }}>Name</span>}
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: "0.875rem" }}>Email</span>}
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
              type: "email",
            },
          ]}
        >
          <Input disabled={userData ? true : false} />
        </Form.Item>

        <Form.Item label="Password" name="password">
          <Input.Password
            disabled={userData ? true : false}
            style={{ width: "calc(100% - 170px)" }}
          />
        </Form.Item>

        {userData ? null : (
          <Form.Item
            // wrapperCol={{ offset: 7, span: 15 }}
            style={{
              position: "absolute",
              right: "8.3%",
              top: "111px",
              width: "150px",
            }}
          >
            <Button onClick={generatePassword}>Generate Password</Button>
          </Form.Item>
        )}

        <Form.Item
          label={<span style={{ fontSize: "0.875rem" }}>Company</span>}
          name="company"
          rules={[{ required: true, message: "Please input your company!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: "0.875rem" }}>Select Platform</span>}
          name="platform"
          rules={[{ required: true, message: "Please check the platform!" }]}
          initialValue="Synapse"
        >
          <Radio.Group options={["Synapse"]} />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: "0.875rem" }}>Account Type</span>}
          name="accountType"
          rules={[{ required: true, message: "Please select account type!" }]}
        >
          <Radio.Group
            options={
              isPartner === true
                ? ["View"]
                : ["Admin", "View", "View and Download"]
            }
          />
        </Form.Item>

        <Form.Item
          label={
            <span style={{ fontSize: "0.875rem" }}>Relationship Manager</span>
          }
          name="relationshipManagerEmail"
          rules={[
            { required: true, message: "Please select Relationship Manager!" },
          ]}
        >
          <Select
            loading={relationshipManagerEmailLoading}
            showSearch
            placeholder="Select a Relation Manager"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {relationshipManagerEmailData?.data.map((item, index) => (
              <Option key={index} value={item.email_id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {role !== "SALES_EXECUTIVE" && (
          <>
            <Form.Item
              label={
                <span
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    style={{
                      color: "#ff4d4f",
                      fontSize: "0.875rem",
                    }}
                  >
                    *
                  </span>{" "}
                  <span style={{ fontSize: "0.875rem" }}>
                    {userData ? "Remaining Analyst Hours " : "Analyst Hours "}
                  </span>
                </span>
              }

              // label="Credits Library"
              // labelCol={{ style: { flexWrap: "wrap" } }} // Ensures label wraps instead of overlapping
              // wrapperCol={{ span: 24 }} // Adjust the span to ensure input fields take full width
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="analystHours"
                    rules={[
                      {
                        required: true,
                        message: `
                    Please input ${userData ? "remaining" : null} Analyst Hours!
                    `,
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      min={0}
                      placeholder={userData ? "Remaining" : "Total"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {userData && (
                <Form.Item wrapperCol={{ offset: 0, span: 15 }}>
                  {/* Create Label */}
                  <span>Total : {userData?.analyst_hours}</span>
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item
              label={
                <span
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    style={{
                      color: "#ff4d4f",
                      fontSize: "0.875rem",
                    }}
                  >
                    *
                  </span>{" "}
                  <span style={{ fontSize: "0.875rem" }}>{`${
                    userData ? "Remaining Credits" : "Credits"
                  } On Library`}</span>
                </span>
              }

              // label="Credits Library"
              // labelCol={{ style: { flexWrap: "wrap" } }} // Ensures label wraps instead of overlapping
              // wrapperCol={{ span: 24 }} // Adjust the span to ensure input fields take full width
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="creditsLibraryTotal"
                    rules={[
                      {
                        required: true,
                        message: `
                    Please input ${
                      userData ? "remaining" : "total"
                    } credits for Library!
                    `,
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      min={0}
                      placeholder={userData ? "Remaining" : "Total"}
                    />
                  </Form.Item>

                  {/* </Col> */}
                  {/* <Col span={12}> */}
                  {/* <Form.Item
                name="creditsLibraryRemaining"
                rules={[
                  {
                    required: true,
                    message: "Please input remaining credits for library!",
                  },
                ]}
              >
                <InputNumber min={0} placeholder="Remaining" />
              </Form.Item> */}
                </Col>
              </Row>
              {userData && (
                <Form.Item wrapperCol={{ offset: 0, span: 15 }}>
                  {/* Create Label */}
                  <span>Total : {userData?.library_credits?.total}</span>
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item
              label={
                <span
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    style={{
                      color: "#ff4d4f",
                      fontSize: "0.875rem",
                    }}
                  >
                    *
                  </span>{" "}
                  <span style={{ fontSize: "0.875rem" }}>{`${
                    userData ? "Remaining Credits" : "Credits"
                  } On Demand`}</span>
                </span>
              }
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="onDemandTotal"
                    rules={[
                      {
                        required: true,
                        message: `Please input ${
                          userData ? "remaining" : "total"
                        } credits for On Demand!`,
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      min={0}
                      placeholder={userData ? "Remaining" : "Total"}
                    />
                  </Form.Item>
                  {/* <Form.Item
                name="onDemandRemaining"
                rules={[
                  {
                    required: true,
                    message: "Please input remaining credits for On Demand!",
                  },
                ]}
              >
                <InputNumber min={0} placeholder="Remaining" />
              </Form.Item> */}
                  {userData && (
                    <Form.Item wrapperCol={{ offset: 0, span: 15 }}>
                      {/* Create Label */}
                      <span>Total : {userData?.ondemand_credits?.total}</span>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Form.Item>
          </>
        )}
        {role === "SALES_EXECUTIVE" && (
          <Form.Item
            label={<span style={{ fontSize: "0.875rem" }}>Hub List</span>}
            name="hubList"
            // rules={[
            //   { required: true, message: "Please select at least one hub!" },
            // ]}
          >
            <Select
              mode="multiple"
              placeholder="Select hubs"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >=
                0
              }
            >
              {hubListData?.data?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {/* //Add custom user field checkbox */}
        <Form.Item
          label={<span style={{ fontSize: "0.875rem" }}>Custom User</span>}
          name="custom_user"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          wrapperCol={{ offset: 8, span: 16 }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button type="primary" htmlType="submit" style={{ width: "100px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default NewCreateUser;
