import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Checkbox } from "antd";
import {
  useCreateCMSUserMutation,
  useEditCMSUserMutation,
} from "../../../redux/api/createUserApi";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { LeftOutlined } from "@ant-design/icons";

const CMSUser = ({ userData, setToSearchScreen }) => {
  const newEmailFormat = [
    { required: true, message: "Please input your email!" },
    { type: "email", message: "Please enter a valid email!" },
    {
      pattern: /^[a-zA-Z0-9._%+-]+@mordorintelligence.com$/,
      message: "Domain should be mordorintelligence.com",
    },
  ];
  const editEmailFormat = [
    { required: true, message: "Please input your email!" },
    { type: "email", message: "Please enter a valid email!" },
  ];
  const [form] = Form.useForm();
  const [
    createCMSUser,
    { data: createCMSUserData, error: createCMSUserError },
  ] = useCreateCMSUserMutation();

  const [editCMSUser, { data: editCMSUserData, error: editCMSUserError }] =
    useEditCMSUserMutation();

  const designations = Form.useWatch("designation", form);

  const onFinish = (values) => {
    if (userData) {
      editCMSUser({ ...values, id: userData.id });
      return;
    }
    createCMSUser(values);
  };
  const cookies = new Cookies();
  const role = cookies.get("ROLE");

  const generatePassword = () => {
    const length = Math.floor(Math.random() * (30 - 8 + 1)) + 8;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  useEffect(() => {
    if (createCMSUserData) {
      form.resetFields();
      toast.success("User created successfully");
    }
    if (createCMSUserError) {
      toast.error("Failed to create user");
    }
  }, [createCMSUserData, createCMSUserError]);

  useEffect(() => {
    if (editCMSUserData) {
      form.resetFields();
      toast.success("User updated successfully");
      setToSearchScreen(true);
    } else if (editCMSUserError) {
      toast.error("Failed to update user");
    }
  }, [editCMSUserData, editCMSUserError]);

  const [DesignationData, setDesignationData] = useState([
    {
      name: "SUPER ADMIN",
      value: "SUPER_ADMIN",
    },
    {
      name: "SALES MANAGER",
      value: "SALES_MANAGER",
    },
    {
      name: "SALES EXECUTIVE",
      value: "SALES_EXECUTIVE",
    },
    {
      name: "FORMATTING MANAGER",
      value: "FORMATTING_MANAGER",
    },
    {
      name: "FORMATTING EXECUTIVE",
      value: "FORMATTING_EXECUTIVE",
    },
  ]);

  useEffect(() => {
    if (role === "FORMATTING_MANAGER") {
      setDesignationData([
        {
          name: "FORMATTING MANAGER",
          value: "FORMATTING_MANAGER",
        },
        {
          name: "FORMATTING EXECUTIVE",
          value: "FORMATTING_EXECUTIVE",
        },
      ]);
    } else if (role === "SALES_MANAGER") {
      setDesignationData([
        {
          name: "SALES MANAGER",
          value: "SALES_MANAGER",
        },
        {
          name: "SALES EXECUTIVE",
          value: "SALES_EXECUTIVE",
        },
      ]);
    }
  }, [role]);

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        name: userData.name,
        email: userData.email,
        password: userData.password,
        designation: userData.designation,
      });
    }
  }, []);

  return (
    <div>
      {userData && (
        <Button
          icon={<LeftOutlined />}
          onClick={() => {
            // window.location.reload();
            setToSearchScreen(true);
          }}
        >
          Go To User List
        </Button>
      )}
      <Form
        form={form}
        name="cms_user_form"
        onFinish={onFinish}
        style={{
          width: "50%",
          margin: "auto",
          marginTop: "1rem",
          position: "relative",
        }}
        layout="horizontal"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 15 }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={userData ? editEmailFormat : newEmailFormat}
        >
          <Input disabled={userData ? true : false} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please input your password!" },
            {
              min: 8,
              message: "Password should be minimum 8 characters",
            },
            {
              max: 30,
              message: "Password should be maximum 30 characters",
            },
          ]}
        >
          <Input.Password
            disabled={userData ? true : false}
            style={{ width: "calc(100% - 170px)" }}
          />
        </Form.Item>

        {userData ? null : (
          <Form.Item
            // wrapperCol={{ offset: 7, span: 15 }}
            style={{
              position: "absolute",
              right: "8.3%",
              bottom: "88px",
              width: "150px",
            }}
          >
            <Button
              onClick={(e) => {
                e.preventDefault();
                form.setFieldsValue({ password: generatePassword() });
              }}
            >
              Generate Password
            </Button>
          </Form.Item>
        )}

        <Form.Item
          label="Designation"
          name="designation"
          rules={[
            { required: true, message: "Please input your designation!" },
          ]}
        >
          <Select placeholder="Select your designation">
            {DesignationData.map((item, index) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
        </Form.Item>

        {designations === "SALES_EXECUTIVE" && (
          <Form.Item
            name="partneredAccount"
            valuePropName="checked"
            wrapperCol={{ offset: 7, span: 15 }}
          >
            <Checkbox>Partnered Account</Checkbox>
          </Form.Item>
        )}

        <Form.Item
          wrapperCol={{ offset: 8, span: 16 }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button type="primary" htmlType="submit" style={{ width: "100px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CMSUser;
