import React from "react";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { Button, Row, Col } from "antd";
import styled from "styled-components";
import {
  FileOutlined,
  UserOutlined,
  SettingOutlined,
  FileTextOutlined,
  BarChartOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";

import NavBar from "../../components/NavBar";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import { ExportOutlined } from "@ant-design/icons";
// import { Button } from "antd";
import axios from "axios";
import API from "../../utils/api";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
let _id = 0;

const buttonData = [
  // { name: "Export Synapse Library Report", bgColor: "#f56a00", icon: <FileOutlined /> },
  // { name: "Export User", bgColor: "#7265e6", icon: <UserOutlined /> },
  { name: "User Management", bgColor: "#ffbf00", icon: <SettingOutlined /> },
  { name: "Content Management", bgColor: "#00a2ae", icon: <FileTextOutlined /> },
  { name: "Custom Report Management", bgColor: "#7cb305", icon: <DatabaseOutlined /> },
  { name: "Power BI Integration", bgColor: "#ff4d4f", icon: <BarChartOutlined /> },
];

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  width: 100%; 
  max-width: 313px;
  min-height: 104px;
  padding: 20px; 
  border: 4px solid ${(props) => props.bgColor};
  color: ${(props) => props.bgColor};
  background: transparent;
  transition: all 0.3s ease-in-out;
  flex-shrink: 0; 
  border-radius: 10px;


  &:hover {
    background-color: ${(props) => `${props.bgColor}c9`} !important; 
    color: #fff !important; 
    border-color: ${(props) => props.bgColor} !important; 
  }

  .anticon {
    font-size: 64px; 
    margin-right: 16px;
  }

  @media (max-width: 768px) {
    max-width: 313px; /
    height: 107px;
    font-size: 14px;

    .anticon {
      font-size: 24px;
    }
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: wrap; 
    font-size:22px;
    display: inline-table
  }
`;

const GradientHeading = styled.h1`
  font-size: 28px;
  font-weight: bold;
  background-image: linear-gradient(
    to right,
    #f56a00, 
    #7265e6, 
    #ffbf00, 
    #00a2ae, 
    #7cb305, 
    #ff4d4f  
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; 
  margin-bottom: 40px; 
`;


function Dashboard() {
  const isNavBarShrink = useSelector((store) => store.interface.isNavBarShrink);
  const cookies = new Cookies();

  let token = cookies.get("TOKEN");
  const navigate = useNavigate()

  const { Base_URL } = API;
  const [isLoading, setIsLoading] = React.useState(false);

  async function exportReport(slug, id) {
    _id = id;
    try {
      setIsLoading(true);
      axios
        .get(`${Base_URL}/${slug}`, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response?.data?.message) {
            toast.success(response.data.message);
          } else {
            toast.error("Error exporting reports");
          }
        })
        .finally(() => {
          setIsLoading(false);
          id = 0;
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleClick = (name) => {
    if (name === "User Management") {
      navigate('/home/user-management/create-user')
    }
    if (name === "Content Management") {
      navigate('/home/content-management/view-and-edit-reports')
    }
    if (name === "Custom Report Management") {
      navigate('/home/custom-reports-management/upload-files-2')
    }
    if (name === "Power BI Integration") {
      navigate('/home/power-bi-integration')
    }

  }
  return (
    <Row justify="center" align="middle" style={{ minHeight: "60vh" }}>
      <Col>
        <GradientHeading>WELCOME TO CMS</GradientHeading>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "40px" }}>
          <StyledButton
            bgColor={"#04bcfc"}
            loading={isLoading && _id === 1}
            // icon={<ExportOutlined />}
            onClick={() => exportReport("exportSynapseReports", 1)}
          >
            {isLoading && _id === 1 ? "" : <FileOutlined />}
            <span>Export Synapse Library Report</span>
          </StyledButton>
          <StyledButton
            bgColor={"#199b77"}
            loading={isLoading && _id === 2}
            // icon={<ExportOutlined />}
            onClick={() => exportReport("exportUsers", 2)}
          >
            {isLoading && _id === 2 ? "" : <UserOutlined />}
            <span>Export User</span>
          </StyledButton>
          {buttonData.map((btn, index) => (
            <StyledButton
              key={index}
              bgColor={btn.bgColor}
              onClick={() => handleClick(btn.name)}
            >
              {btn.icon}
              <span>{btn.name}</span>
            </StyledButton>
          ))}
        </div>
      </Col>
    </Row>
  );

  // return (
  //   <div>
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "space-between",
  //         width: "100%",
  //       }}
  //     >
  //       <Button
  // loading={isLoading && _id === 1}
  // icon={<ExportOutlined />}
  // onClick={() => exportReport("exportSynapseReports", 1)}
  //         type="primary"
  //       >
  //         Export Synapse Library Reports
  //       </Button>
  //       <Button
  //         loading={isLoading && _id === 2}
  //         icon={<ExportOutlined />}
  //         onClick={() => exportReport("exportUsers", 2)}
  //         type="primary"
  //       >
  //         Export Users
  //       </Button>
  //     </div>
  //     {/* <h1 >Dashboard</h1> */}
  //   </div>
  // );
}

export default Dashboard;
