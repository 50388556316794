// import React from "react";
// import { Input, List, Tooltip } from "antd";
// import UserListWrapper from "./UserList.style";
// import Icon, { FilePdfOutlined, FolderOpenOutlined } from "@ant-design/icons";
// import Fuse from "fuse.js";
// import _ from "lodash";

// const sampleData = [
//   // {
//   //   name: "John Doe",
//   //   email: "john.doe@example.com",
//   //   id: 1,
//   // },
//   // {
//   //   name: "Jane Smith",
//   //   email: "jane.smith@example.com",
//   //   id: 2,
//   // },
//   // {
//   //   name: "Bob Johnson",
//   //   email: "bob.johnson@example.com",
//   //   id: 3,
//   // },
//   // Add more users as needed
// ];

// const UserList = ({ data = sampleData, getUserReports, setUser, from }) => {
//   let userSearchFuse;
//   const [userFilteredData, setUserFilteredData] = React.useState([]);
//   React.useEffect(() => {
//     if (data) {
//       userSearchFuse = new Fuse(data, {
//         threshold: 0.2,
//         keys: ["name", "email"], // replace with your own keys
//         useExtendedSearch: true,
//       });
//     }
//     setUserFilteredData(data);
//   }, [data]);

//   const onSearch = (query) => {
//     if (!query) {
//       setUserFilteredData(data);
//       return;
//     }
//     if (!userSearchFuse) {
//       userSearchFuse = new Fuse(data, {
//         threshold: 0.2,
//         keys: ["name", "email"], // replace with your own keys
//         includeScore: true,
//         useExtendedSearch: true,
//       });
//     }
//     const result = userSearchFuse.search(query);
//     setUserFilteredData(result.map((r) => r.item));
//   };

//   return (
//     <UserListWrapper>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//           padding: "15px",
//         }}
//       >
//         <h2
//           style={{
//             textTransform: "capitalize",
//             fontSize: "18px",
//             color: "#002f75",
//           }}
//         >
//           Please select the client to start assigning the reports.
//         </h2>
//         <Input
//           style={{
//             width: "20%",
//             marginLeft: "auto",
//             //Highlight the search bar
//             border: "1px solid #67bfff",
//           }}
//           placeholder="Search Users"
//           onChange={(e) => onSearch(e.target.value)}
//         />
//       </div>
//       <List
//         style={{ border: "1px solid lightgrey" }}
//         header={
//           <div
//             className="flex header-user-wrapper"
//             style={{
//               padding: "15px",
//               backgroundColor: "#67bfff",
//               borderBottom: "1px solid lightgray",
//             }}
//           >
//             <p style={{ color: "white" }} className="user-name">
//               Name
//             </p>
//             <p style={{ color: "white" }} className="user-name">
//               Email ID
//             </p>
//             <p style={{ color: "white" }} className="user-name">
//               Access Type
//             </p>
//             <p style={{ color: "white" }} className="user-name">
//               Company Name
//             </p>
//           </div>
//         }
//         dataSource={userFilteredData}
//         renderItem={(user, i) => (
//           <List.Item
//             key={i + 1}
//             style={{
//               background: i % 2 !== 0 ? "#f0f7fc" : "white",
//               padding: "15px",
//               borderBottom: "1px solid lightgray",
//             }}
//           >
//             <div className="flex user-wrapper">
//               <p className="user-name">{user.name}</p>
//               <p className="user-name" style={{ marginLeft: "1.1rem" }}>
//                 {user.email}
//               </p>
//               <p className="user-name" style={{ marginLeft: "1.1rem" }}>
//                 {_.capitalize(user?.access_type)}
//               </p>
//               <p className="user-name" style={{ marginLeft: "1.1rem" }}>
//                 {user?.company_name}
//               </p>
//               <div>
//                 <Tooltip title="Assign Reports">
//                   <FolderOpenOutlined
//                     style={{
//                       display:
//                         from !== "assign-reports" ? "inline-block" : "none",
//                     }}
//                     type="folder-open"
//                     onClick={() =>
//                       getUserReports(user?._id, user?.email, user?.name)
//                     }
//                     className="view-reports-icon"
//                   />
//                   <FilePdfOutlined
//                     style={{
//                       display:
//                         from === "assign-reports" ? "inline-block" : "none",
//                     }}
//                     type="file-pdf"
//                     onClick={() =>
//                       getUserReports(user?._id, user?.email, user?.name)
//                     }
//                     className="view-reports-icon"
//                   />
//                 </Tooltip>
//               </div>
//             </div>
//           </List.Item>
//         )}
//         pagination={true}
//       />
//     </UserListWrapper>
//   );
// };

// export default UserList;

import React from "react";
import { Input, Table, Tooltip } from "antd";
import { FolderOpenOutlined, FilePdfOutlined } from "@ant-design/icons";
import _ from "lodash";
import Fuse from "fuse.js";

const UserTable = ({ data, getUserReports, setUser, from }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="user-name">{text}</p>,
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
      render: (text) => <p className="user-name">{text}</p>,
    },
    {
      title: "Access Type",
      dataIndex: "access_type",
      key: "access_type",
      render: (text) => <p className="user-name">{_.capitalize(text)}</p>,
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      render: (text) => <p className="user-name">{text}</p>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, user) => (
        <Tooltip title="Assign Reports">
          <FolderOpenOutlined
            style={{
              display: from !== "assign-reports" ? "inline-block" : "none",
              fontSize: "20px",
            }}
            type="folder-open"
            onClick={() => getUserReports(user._id, user.email, user.name, user.custom_user)}
            className="view-reports-icon"
          />
          <FilePdfOutlined
            style={{
              display: from === "assign-reports" ? "inline-block" : "none",
              fontSize: "1.3rem",
            }}
            type="file-pdf"
            onClick={() => getUserReports(user._id, user.email, user.name, user.custom_user)}
            className="view-reports-icon"
          />
        </Tooltip>
      ),
    },
  ];
  let userSearchFuse;
  const [userFilteredData, setUserFilteredData] = React.useState([]);
  React.useEffect(() => {
    if (data) {
      userSearchFuse = new Fuse(data, {
        threshold: 0.2,
        keys: ["name", "email"], // replace with your own keys
        useExtendedSearch: true,
      });
    }
    setUserFilteredData(data);
  }, [data]);
  const onSearch = (query) => {
    if (!query) {
      setUserFilteredData(data);
      return;
    }
    if (!userSearchFuse) {
      userSearchFuse = new Fuse(data, {
        threshold: 0.2,
        keys: ["name", "email"], // replace with your own keys
        includeScore: true,
        useExtendedSearch: true,
      });
    }
    const result = userSearchFuse.search(query);
    setUserFilteredData(result.map((r) => r.item));
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        <Input
          style={{
            width: "20%",
            marginLeft: "auto",
            //Highlight the search bar
            border: "1px solid #67bfff",
          }}
          placeholder="Search Users"
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      <Table
        className="custom-table-style"
        columns={columns}
        dataSource={userFilteredData.length > 0 ? userFilteredData : data}
        rowKey={(record) => record._id}
        pagination={true}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "even-row" : "odd-row"
        }
        style={{ border: "1px solid lightgrey" }}
      />
    </>
  );
};

export default UserTable;
