import React, { useState, useRef, useEffect } from "react";
import API from "../../utils/api";
import axios from "axios";
import { Button, Checkbox, Typography, MenuItem, Select } from "@mui/material";
import { Input, Tabs } from "antd";
import Loader from "../../components/Common/Loader/Loading";
import { assignReportApi } from "../../redux/api/assignReportApi";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import TabPane from "antd/es/tabs/TabPane";

const UploadFiles = () => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const { Base_URL, resetPassword } = API;
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState("");
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [selectedFileTypeDragger, SetSelectedFileTypeDragger] = useState(null);
  const [version, setVersion] = useState("1");
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const [uploadURL, setUploadURL] = useState("");
  // const [isChecked, setIsChecked] = useState(false);
  const cookies = new Cookies();
  let token = cookies.get("TOKEN");
  const [searchType, setSearchType] = useState("title");
  const [loadingQuery, setLoadingQuery] = useState(false);

  // Function to perform API call for searching files
  const searchFiles = async (query) => {
    // setLoadingQuery(true)
    try {
      const response = await axios.get(
        `${Base_URL}searchSynapseReports?${searchType}=${query}`
      );
      setSearchResults(response.data.data);
    } catch (error) {
      console.error("Error searching files:", error);
    } finally {
      setLoadingQuery(false);
    }
  };

  // Debouncing mechanism to delay API calls
  useEffect(() => {
    setLoadingQuery(true);
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        searchFiles(searchQuery);
      } else {
        setSearchResults([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  // Function to handle selecting a search result
  const handleSelectResult = (result) => {
    setSearchQuery(""); // Set search query to selected result
    setSelectedResult(result);
    //   setReportId()
    setSearchResults([]); // Clear search results
  };

  // const handleBlurChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!file) {
      alert("Please select a file");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("report_id", selectedResult.synapse_report_id);
    formData.append("slug", selectedResult.slug);
    formData.append("version", version);
    formData.append("from", "synapse_report");
    if (comment) {
      formData.append("comment", comment);
    }
    if (selectedFileType === "pdf") {
      formData.append("blur_pdf_content", "no"); // we can change this later
      // formData.append("blur_pdf_content", isChecked ? "yes" : "no");
    }
    try {
      const response = await axios.post(`${Base_URL}upload-sheet`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.url) {
        setUploadURL(response.data.url);
      }
      alert("File uploaded successfully!");
      setFile(null);
      setSelectedResult("");
      setSelectedFileType(null);
      SetSelectedFileTypeDragger(null);
      setLoading(false);
      // setIsChecked(false);
      setVersion("1");
      setComment("");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(
        "Error uploading file, Do know larger file size may take longer time to upload and limit is 100 seconds. Please have good internet connection."
      );
      setLoading(false);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    // Check if the dropped file type is allowed
    if (droppedFile.type === selectedFileTypeDragger) {
      setFile(droppedFile);
    } else {
      alert(`Only ${selectedFileType} files are allowed`);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileUpload = (event) => {
    dispatch(assignReportApi.util.resetApiState());
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  const handleButtonClick = (fileType) => {
    setSelectedFileType(fileType);
    setFile(null);
    if (fileType === "pdf") {
      SetSelectedFileTypeDragger("application/pdf");
    } else if (fileType === "ppt") {
      SetSelectedFileTypeDragger("application/vnd.ms-powerpoint");
    } else if (fileType === "xlsx") {
      SetSelectedFileTypeDragger(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    } else {
      SetSelectedFileTypeDragger("text/csv");
    }
  };

  const handleUpload = () => {
    // Logic to upload file

    // Reset file state
    setFile(null);
  };

  return (
    <>
      {/* <Typography>Upload Files Here</Typography> */}
      <div style={{ width: "70%", justifyContent: "center", margin: "auto" }}>
        <Loader show={loading} />
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}
        >
          <Typography
            sx={{
              flex: "3",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
              marginTop: "6px",
              fontSize: "17px",
            }}
          >
            Search Report :{" "}
          </Typography>
          <div style={{ flex: "7", display: "flex" }}>
            <div style={{ width: "20%" }}>
              <Select
                value={searchType}
                onChange={(e) => {
                  setSearchType(e.target.value);
                  setSelectedResult("");
                  setSearchQuery("");
                }}
                style={{
                  width: "100%",
                  height: "30px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <MenuItem value="title">Title</MenuItem>
                <MenuItem value="slug">Slug</MenuItem>
                <MenuItem value="id">ID</MenuItem>
              </Select>
            </div>
            <div style={{ position: "relative", width: "100%", gap: "10px" }}>
              <Input
                type={searchType == "id" ? "number" : "text"}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Input here"
                style={{
                  width: "100%",
                  height: "30px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              />
              {searchQuery.length > 0 &&
                searchResults.length == 0 &&
                !loadingQuery && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      zIndex: 1,
                      padding: "0.5rem",
                      margin: 0,
                      borderRadius: "0 0 5px 5px",
                      maxHeight: "300px",
                      overflowY: "scroll",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    No data found{" "}
                  </div>
                )}
              {searchResults.length > 0 && (
                <ul
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    zIndex: 1,
                    padding: "0.5rem",
                    margin: 0,
                    borderRadius: "0 0 5px 5px",
                    maxHeight: "300px",
                    overflowY: "scroll",
                    cursor: "pointer",
                  }}
                >
                  {searchResults?.map((result, index) => (
                    <li
                      key={index}
                      style={{
                        borderBottom: "1px solid lightgrey",
                        padding: "5px",
                      }}
                      onClick={() => handleSelectResult(result)}
                    >
                      {result.title} - {result.forecast_year}
                    </li>
                  ))}
                </ul>
              )}
              {selectedResult.title && (
                <div>
                  {selectedResult.title}
                  <button
                    onClick={() => setSelectedResult("")}
                    style={{
                      color: "white",
                      background: "red",
                      padding: "5px",
                      border: "none",
                      marginLeft: "10px",
                    }}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginTop: "10px",
          }}
        >
          <Typography
            sx={{
              flex: "3",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
              fontSize: "17px",
            }}
          >
            Select File Type :{" "}
          </Typography>
          <div style={{ display: "flex", gap: "10px", flex: "7" }}>
            <Button
              variant={selectedFileType === "csv" ? "contained" : "outlined"}
              onClick={() => handleButtonClick("csv")}
            >
              CSV File
            </Button>
            <Button
              variant={selectedFileType === "ppt" ? "contained" : "outlined"}
              onClick={() => handleButtonClick("ppt")}
            >
              PPT File
            </Button>
            <Button
              variant={selectedFileType === "xlsx" ? "contained" : "outlined"}
              onClick={() => handleButtonClick("xlsx")}
            >
              XLSX File
            </Button>
            <Button
              variant={selectedFileType === "pdf" ? "contained" : "outlined"}
              onClick={() => handleButtonClick("pdf")}
            >
              PDF File
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              flex: "3",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
            }}
          ></div>
          {/* Checkbox to blur pdf */}
          {/* <div style={{ display: "flex", gap: "10px", flex: "7" }}>
            {selectedFileType === "pdf" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={true}
                  // onChange={handleBlurChange}
                  color="primary"
                  sx={{ padding: "0px" }}
                />
                <Typography variant="body2">Blur PDF</Typography>
              </div>
            ) : (
              ""
            )}
          </div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              flex: "3",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
              fontSize: "17px",
            }}
          >
            Dragger :{" "}
          </Typography>
          <div
            style={{
              border: "2px dashed #ccc",
              borderRadius: "5px",
              textAlign: "center",
              cursor: selectedFileType ? "pointer" : "not-allowed",
              flex: "7",
            }}
            onClick={
              selectedFileType ? () => fileInputRef.current.click() : null
            }
            onDrop={selectedFileType ? handleDrop : null}
            onDragOver={selectedFileType ? handleDragOver : null}
          >
            {file ? (
              <p style={{ margin: "50px" }}>File selected: {file.name}</p>
            ) : (
              <p style={{ margin: "50px" }}>
                {selectedFileType
                  ? "Drag and drop file here, or click to select file"
                  : "Please select a file type"}
              </p>
            )}
          </div>
          <input
            type="file"
            accept={selectedFileType ? `.${selectedFileType}` : ""}
            style={{ display: "none" }}
            onChange={handleFileUpload}
            ref={fileInputRef}
          />
        </div>
        {/* Version selector and comment box */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              flex: "3",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
              fontSize: "17px",
            }}
          >
            Select Version :{" "}
          </Typography>
          <div style={{ display: "flex", gap: "10px", flex: "7" }}>
            <Select
              value={version}
              onChange={(e) => setVersion(e.target.value)}
              sx={{
                width: "100%",
                height: "30px",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              {[...Array(10).keys()].map((val) => (
                <MenuItem key={val} value={(val + 1).toString()}>
                  Version {val + 1}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              flex: "3",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
              fontSize: "17px",
            }}
          >
            Comments :{" "}
          </Typography>
          <div style={{ display: "flex", gap: "10px", flex: "7" }}>
            <Input.TextArea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Optional comments"
              rows={4}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              //   flex: "3",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              //   marginRight: "10px",
            }}
          >
            <Button
              variant="contained"
              color="success"
              disabled={!selectedResult || !file || loading}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>

          {/* <div style={{ display: "flex", gap: "10px", flex: "7" }}></div> */}
        </div>
        {uploadURL && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                flex: "3",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
                marginTop: "6px",
                fontSize: "17px",
              }}
            >
              Generated PDF:
            </Typography>
            <div style={{ flex: "7", display: "flex" }}>
              <div style={{ position: "relative", width: "100%", gap: "10px" }}>
                <a href={uploadURL} target="_blank" rel="noopener noreferrer">
                  {uploadURL}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadFiles;
